import React from "react";
import '../TopPhysicians/Css/style.css';
import d1 from "./Images/d1.jpeg"
import c1 from "./Images/c1.jpg"
import d2 from "./Images/d2.jpeg"
import c2 from "./Images/c2.jpg"
import d3 from "./Images/d3.jpg"
import c3 from "./Images/c3.jpeg"
import d4 from "./Images/d4.jpg"
import c4 from "./Images/c4.jpeg"
import d5 from "./Images/d5.jpg"
import c5 from "./Images/c5.png"
import d6 from "./Images/d6.jpeg"
import c6 from "./Images/c6.png"
import d7 from "./Images/d7.jpeg"
import c7 from "./Images/c7.jpeg"
import d8 from "./Images/d8.jpeg"
import c8 from "./Images/c8.jpeg"
import d9 from "./Images/d9.png"
import c9 from "./Images/c9.jpeg"
import d10 from "./Images/d10.jpeg"
import c10 from "./Images/c10.png"
import d11 from "./Images/d11.jpg"
import c11 from "./Images/c11.png"
// import doctorImage from './Images/DocImage.png';

// import degree from './Images/degree.png';

const physicians = [
  { id: 5, frontImg: d5, backImg: c5 , name: "Dr Rajeena", degreeName: "ESIC Medical College, Faridabad", exp: '14'},
  { id: 2, frontImg: d2, backImg: c2 , name: "Dr Ankesh", degreeName: "Medanta Institute of Education and Research", exp: '10'},
  { id: 11, frontImg: d11, backImg: c11, name: "Dr Vivek", degreeName: "AIIMS, Delhi", exp: '8'},
  { id: 10, frontImg: d10, backImg: c10 , name: "Dr. Abhishek K", degreeName: "JJM Medical College, Karnataka", exp: '8'},
  { id: 4, frontImg: d4, backImg: c4 , name: "Dr Ashwani", degreeName: "University College of Medical Sciences, Delhi", exp: '7'},
  { id: 8, frontImg: d8, backImg: c8 , name: "Dr Shalini", degreeName: "GSL Medical College, Haryana", exp: '7'},
  { id: 6, frontImg: d6, backImg: c6 , name: "Dr Prabhu", degreeName: "Kongunadu Institute of Allied Health Science, Tamil Nadu", exp: '6'},
  { id: 9, frontImg: d9, backImg: c9 , name: "Dr Shashank", degreeName: "Hind Institute of Medical Sciences, UP", exp: '6'},
  { id: 1, frontImg: d1, backImg: c1 , name: "Dr Aditya", degreeName: "NDMC Hospital, Delhi", exp: '5'},
  { id: 3, frontImg: d3, backImg: c3 , name: "Dr Abhishek L", degreeName: "Government Medical College, Karnataka", exp: '5'},
  { id: 7, frontImg: d7, backImg: c7 , name: "Dr Sanjeev", degreeName: "PGIMS, Rohtak", exp: '5'},
    // { id: 12, frontImg: doctorImage, backImg: degree },
  ];

function TopPhysicians(){
    return (
        <div className="top-physicians">
          <h2>Top Physicians</h2>
          <p>
            Our doctors come with years of experience at top institutes
            & hospitals such as Fortis, Medanta, AIIMS, Safdarjung, etc.
          </p>
          <div className="physicians-grid">
            {physicians.map((doc) => (
              <div key={doc.id} className="flip-container">
                <div className="flipper">
                  <div className="front">
                    <img style={doc.id === 2 || doc.id === 6 ? { transform: "rotate(90deg)"}: {}} src={doc.frontImg} alt="Doctor" />
                  </div>
                  <div className="back">
                  <div className="doctor_name">{doc.name}</div>
                  <div className="degree_name">{doc.exp}+ years experience</div>
                    {/* <img src={doc.backImg} alt="Medical Logo" /> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
}

export default TopPhysicians;