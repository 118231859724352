import React from 'react';
import Header from './lendingPage/Header/index';
import Footer from './lendingPage/Footer/index';
import TopPhysicians from './lendingPage/TopPhysicians';
import Feedback from './lendingPage/Feedback';
import Faqs from './lendingPage/Faqs';
import Booking from './lendingPage/Booking';
import WhyChoose from './lendingPage/WhyChoose';
import Counting from './lendingPage/Counting';
import DoctorConsult from './lendingPage/DoctorConsult';
import DownloadApp from './lendingPage/DownloadApp';
import Comparision from './lendingPage/Comparision';
import HomeVisit from './lendingPage/HomeVisit';
import HowItWorks from './lendingPage/HowItWorks';
import { Helmet } from 'react-helmet'
import logo from "../../src/utilities/images/home_v2/logo.png"

function Lending() {
  const homepagescheme =
  {
    "@context": "https://schema.org",
    "@type": "PrimaryCare",
    "name": "KynoHealth",
    "image": "https://www.kynohealth.com/images/Kyno-footer-logo.png",
    "@id": "https://www.kynohealth.com/",
    "url": "https://www.kynohealth.com/",
    "telephone": "01206056623",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "H NO M 37, THIRD FLOOR, MAYFIELD GARDEN, SECTOR 52, OPPOSITE ARTEMIS HOSPITAL",
      "addressLocality": "Gurgaon",
      "postalCode": "122001",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 28.4253544,
      "longitude": 77.06304709999999
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    },
    "sameAs": [
      "https://www.facebook.com/KynoHealth-106490852367301",
      "https://twitter.com/Kynohealth",
      "https://instagram.com/kynohealth?igshid=YmMyMTA2M2Y=",
      "https://youtube.com/@kynohealth",
      "https://www.linkedin.com/company/kyno-health/",
      "https://in.pinterest.com/kynohealth/",
      "https://www.tumblr.com/kynohealth",
      "https://www.kynohealth.com/"
    ]
  }
  return (
    <div style={{ overflowX: "hidden" }}>
      <script type="application/ld+json">
        {JSON.stringify(homepagescheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Medical Consultation | Best Physician Doctor in Noida</title>
        <meta name="description" content="Looking for a skilled general physician in Noida? Our experienced doctors provide expert medical consultation services and personalized treatment." />
        <link rel="canonical" href="https://www.kynohealth.com/" />

        <meta property='og:image' content={logo} />
        <meta name="google-site-verification" content="EADXQCFm27CfkWKQlFSbbLUSV6Q1I-yh-8taT0qxR-s" />
      </Helmet>
      <Header />
      <HomeVisit />
      <DoctorConsult />
      <Counting />
      <WhyChoose />
      <Comparision />
      <HowItWorks />
      <TopPhysicians />
      <Feedback />
      <Faqs />
      {/* <Booking/> */}
      {/* <DownloadApp /> */}
      <Footer />
    </div >
  );
}
export default Lending;