import React from 'react';
import '../WhyChoose/Css/style.css';
import hour from '../WhyChoose/Images/24-hours.svg'
import followUp from '../WhyChoose/Images/followUp.svg'
import waiting from '../WhyChoose/Images/waiting.svg'
import verified from '../WhyChoose/Images/verified.svg'
import equipped from '../WhyChoose/Images/equipped.svg'

const WhyChoose = () => {
  return (
    <div className="why-choose-kyno">
      <h2 className='kyno-section_title'>Why choose Kyno?</h2>
      <div className='feature-card-section'>
        <div className="feature-card">
          <div className="icon">
            <img src={hour} alt="" />
          </div>
          <div className="content">
            <h3>Available 24/7</h3>
            <p>Round the clock</p>
          </div>
        </div>

        <div className="feature-card">
          <div className="icon">
            <img src={followUp} alt="" />
          </div>
          <div className="content">
            <h3>Convenient follow-ups</h3>
            <p>Comprehensive post-consultation care till recovery</p>
          </div>
        </div>

        <div className="feature-card">
          <div className="icon">
            <img src={waiting} alt="" />
          </div>
          <div className="content">
            <h3>No travel & waiting</h3>
            <p>Avoid queues and receive timely care at home</p>
          </div>
        </div>

        <div className="feature-card">
          <div className="icon">
            <img src={verified} alt="" />
          </div>
          <div className="content">
            <h3>Verified Physicians</h3>
            <p>Experienced and certified doctors from Medanta, Fortis etc. you can trust</p>
          </div>
        </div>

        <div className="feature-card">
          <div className="icon">
            <img src={equipped} alt="" />
          </div>
          <div className="content">
            <h3>Fully Equipped</h3>
            <p>Kyno Doctors come prepared with emergency medicines and injections to your doorstep</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default WhyChoose;
