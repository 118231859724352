import React from "react";
import './Css/style.css'
import quality from './Images/quality.svg';
import growth from './Images/growth.svg';
import transparent from './Images/transparent.svg';
import unbreakable from './Images/unbreakable.svg';
import care from './Images/care.svg';

const Principles = () => {
    return (
        <div className="principles-values">
            <h2 className='section_title'>Our Values and Principles</h2>
            <div className="feature-card-section">

                <div className="values-feature-card">
                    <div className="icon">
                        <img src={quality} alt="" />
                    </div>
                    <div className="content">
                        <h3>Uncompromised Quality</h3>
                        <p>Expert doctors with proven experience, best quality medical supplies</p>
                    </div>
                </div>

                <div className="values-feature-card">
                    <div className="icon">
                        <img src={growth} alt="" />
                    </div>
                    <div className="content">
                        <h3>Growth Together</h3>
                        <p>Partnering with you to improve health outcomes, we continuously strive for better patient experiences.</p>
                    </div>
                </div>

                <div className="values-feature-card">
                    <div className="icon">
                        <img src={transparent} alt="" />
                    </div>
                    <div className="content">
                        <h3>Transparent Process</h3>
                        <p>Every step of our service is clear, reliable, and designed to keep you informed for complete peace of mind.</p>
                    </div>
                </div>

                <div className="values-feature-card">
                    <div className="icon">
                        <img src={unbreakable} alt="" />
                    </div>
                    <div className="content">
                        <h3>Unbreakable Spirit</h3>
                        <p>Our commitment to overcoming challenges ensures that every patient receives consistent and reliable care, no matter what!</p>
                    </div>
                </div>

                <div className="values-feature-card">
                    <div className="icon">
                        <img src={care} alt="" />
                    </div>
                    <div className="content">
                        <h3>Compassionate Care</h3>
                        <p>We go beyond treatment to provide empathy, understanding, and a personalized approach.</p>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Principles;