import React from "react";
import "./Css/style.css";
import mission from './Images/mission.svg';
import vision from './Images/vision.svg';
const Healthier = () => {
    return (
        <div className="healthy_container">
            <h1 className="title">Building a healthier tomorrow</h1>
            <div className="healthy_container-card">
                <div className="card">
                    <div className="card-icon">
                        <img src={mission} alt="" />
                    </div>
                    <h2 className="card-title">The mission</h2>
                    <p className="card-text">
                        Make reliable healthcare accessible at home in 60 mins by combining
                        technology, compassion and patient-first approach.
                    </p>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <img src={vision} alt="" />
                    </div>
                    <h2 className="card-title">The vision</h2>
                    <p className="card-text">
                        Transform primary healthcare by bridging gaps and creating an
                        inclusive, forward-thinking system without the hassle of travel.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Healthier;
