import React from "react";
import './Css/style.css';
import schedule from './Images/schedule.svg';
import consultation from './Images/consultation.svg';
import followUps from './Images/followUps.svg';

function HowItWorks() {
  const steps = [
    {
      icon: schedule,
      title: 'Schedule an appointment',
      description: 'Book an appointment via app, call or website',
    },
    {
      icon: consultation,
      title: 'Detailed Consultation',
      description:
        'Get a comprehensive checkup, prescriptions and medicines from the doctor',
    },
    {
      icon: followUps,
      title: 'Convenient follow ups',
      description:
        'Enjoy your recovery process with seamless follow ups with the doctor',
    },
  ];
  return (<section className="how-it-works">
          <h2>How it works</h2><div className={"stepsContainer"}>
    {steps.map((step, index) => (
      <div key={index} className={"step"}>
        <div className={"icon"}>
          <img src={step.icon} alt={step.title} />
        </div>
        <div>
        <h3>{step.title}</h3>
        <p>{step.description}</p>

        </div>
      </div>
    ))}
  </div></section>)
    return (
        <section className="how-it-works">
          <h2>How it works</h2>
          <div className="steps-container">
            <div className="step">
              <div className="icon">
                <img src={schedule} alt="Schedule an appointment" />
              </div>
              <h3>Schedule an appointment</h3>
              <p>Book an appointment via app, call or website.</p>
            </div>
            <div className="step">
              <div className="icon">
                <img src={consultation} alt="Detailed Consultation" />
              </div>
              <h3>Detailed Consultation</h3>
              <p>Get a comprehensive checkup, prescriptions and medicines from the doctor.</p>
            </div>
            <div className="step">
              <div className="icon">
                <img src={followUps} alt="Convenient follow ups" />
              </div>
              <h3>Convenient follow ups</h3>
              <p>Enjoy your recovery process with seamless follow ups with the doctor.</p>
            </div>
          </div>
        </section>
      );
}

export default HowItWorks;