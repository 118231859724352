import React from "react";
import './Css/style.css';
import successfull from './Images/successfull.png'
import Header from "../Header";

function BookingSuccessfull() {
    return (
        <>
        <Header/>
                <div className="successfull_container">
            <div className="success-icon">
                <img src={successfull} alt="successfull" />
            </div>
            <div className="heading">Booking successful</div>
            <div className="details">
                <div>
                    <span>20 Nov</span>
                    <small>Day</small>
                </div>
                <div>
                    <span>11:30AM</span>
                    <small>Time</small>
                </div>
            </div>
            <div className="info">
                Our team will reach out to you within <strong>10 minutes</strong>.<br />
                In case of any help or queries please reach out. 
                {/* <a href="#">Call us</a> */}
            </div>
            {/* <a href="#" className="button">Go to home</a> */}
        </div>
        </>

    );
}

export default BookingSuccessfull;