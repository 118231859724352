import React from 'react'
import { Outlet, Link } from "react-router-dom";
import './Css/style.css'

function index() {
  return (
    <>
 <section className='fourth-fold'>
  <p className='lkiu'>* Extra charges might apply for night consultations</p>
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-7 col-6'>
<div className='visit-info'>
<h5>Book your first visit for <br/> 
<span><i className='fa fa-inr'></i> <span style={{ textDecoration: "line-through" }}> 1499</span> 999*</span>
</h5>
<div className='bookslot'>

<Link className="btn btn-warning d-sm-none d-md-none d-lg-none" to="tel:+917996643888">Call Now</Link>

</div>
</div>
</div>

<div className='col-md-2 col-4 d-none d-sm-block d-md-block d-lg-block'>
<div className='bookslot'>

<Link className="btn btn-warning " to="tel:+917996643888">Call Now</Link>

</div>
</div>

<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='../images/image-43500.svg'/>
<img className='img-fluid' src='../images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>
    </>
  )
}

export default index