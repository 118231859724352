import React from "react";
import Header from '../Header/index';
import Booking from '../Booking/index';
import DownloadApp from '../DownloadApp/index';
import Footer from '../Footer/index';
import Principles from "../Principles/index";
import Healthier from "../Healthier";
import Existance from '../Existance';
import DocPatientInteraction from '../DocPatientInteraction';
import { Helmet } from "react-helmet";

function Services() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home Visit Doctors Near Me : Bringing Healthcare to Your Doorstep</title>
        <meta name="description"
          content="Access quality healthcare with home visit doctors near you. Convenient and personalized medical care in the comfort of your home. Schedule an appointment today." />
        <link rel="canonical" href="https://www.kynohealth.com/doctors-near-me/" />
      </Helmet>
      <Header />
      <Existance />
      <DocPatientInteraction />
      <Healthier />
      <Principles />
      {/* <DownloadApp /> */}
      <Footer />
    </div>
  );
}

export default Services;