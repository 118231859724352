import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    Button,
    Typography,
    Box,
    Select,
    FormControl,
    MenuItem,
    FormHelperText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './BookingFormNewFlow.css';
import { Helmet } from 'react-helmet'
import logo from "../../utilities/images/home_v2/logo.png"
import Nav from '../home_page_v2/Nav/index'
import Footer from '../home_page_v2/Footer/index'
// import InputWithLabel from './inputWithLabel';
import BookingDetails from './bookingDetails';
import LabelComponent from './labelComponent';
// import ButtonGroup from './buttonGroup';
import CustomButtonGroup from './customButtonGroup';
import useRazorpay from "react-razorpay";
import PaymentStatusPopup from './PaymentStatusPopup';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Fab } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import axios from 'axios';
import InputWithLabel from './inputFormWithConfirm';
import Feedback from '../lendingPage/Feedback';
import { green } from '@mui/material/colors';

// import { makeStyles } from '@mui/styles';
// import logo from "src/utilities/images/home_v2/logo.png"

const BookingFormNewFlow = ({customSrNumber}) => {
    // const { city } = useParams();
    const urlCity = useParams().city
    const [searchParams, setSearchParams] = useSearchParams();
    const urlPhone = searchParams.get("phone")
    const urlOrderId = searchParams.get("orderId")
    const paramsCity = searchParams.get("city")
    const [name, setName] = useState('');
    const [phone, setPhone] = useState(urlPhone || '');
    const [nameError, setNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [bookActive, setBookActive] = useState(false)
    const [slottype, setSlotType] = useState("")
    const [currentOrderId, setCurrentOrderId] = useState(urlOrderId || null)
    const [openPopup, setOpenPopup] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('pending');
    const [starttimeSelected, setStarttimeSelected] = useState(null)
    const [timeFormat, setTimeFormat] = useState(null)
    const [city, setCity] = useState(urlCity || paramsCity || '');
    const [cityError, setCityError] = useState(false);
    const [requestNonce, setRequestNonce] = useState(null);
    const [isPolling, setIsPolling] = useState(false);
    const [pollingCount, setPollingCount] = useState(0);
    const [pollingError, setPollingError] = useState(null);
    const [tcName, setTcName] = useState('');
    const [tcPhone, setTcPhone] = useState('');
    const [tcEmail, setTcEmail] = useState('');
    const isPlumPage = !!window.location.href.includes("plum")
    const maxPollingAttempts = 5;
    const navigate = useNavigate();
    const [step, setStep] = useState(window.location.href.includes("checkout") ? 2 : 1)
    const [isTruecallerOpened, setTruecallerOpened] = useState(false)
    // const headerRef = useRef(null);
    const [contentHeight, setContentHeight] = useState("100vh");
    const [APILoader, setAPILoader] = useState(false)

    const adjustContentHeight = () => {
        const header = document.querySelector(".main-menu");
        if (header && header.offsetHeight) { // Ensure header exists and has offsetHeight
            const headerHeight = header.offsetHeight;
            setContentHeight(`calc(100vh - ${headerHeight}px  - 2px)`);
          }
      };

    useEffect(() => {
        adjustContentHeight(); // Adjust on mount
        window.addEventListener("resize", adjustContentHeight); // Adjust on resize

        return () => {
        window.removeEventListener("resize", adjustContentHeight); // Cleanup on unmount
        };
    }, []);
    
    if (step === 2 && (!phone || !urlOrderId)) {
        window.location.href = "/new-booking/"
    }

    let android = /Android/.test(navigator.userAgent) && !window.MSStream;
    

    // useEffect(() => {
    //   const handlePopState = () => {
    //     // Navigate to the root ("/") when the back button is clicked
    //     navigate("/", { replace: true });
    //   };

    //   // Listen for browser back button (popstate event)
    //   window.addEventListener("popstate", handlePopState);

    //   return () => {
    //     // Clean up event listener on component unmount
    //     window.removeEventListener("popstate", handlePopState);
    //   };
    // }, [navigate]);


    useEffect(() => {
        const today = new Date();
        const tomorrow = new Date(today);
        const dayAfterTomorrow = new Date(today);

        tomorrow.setDate(today.getDate() + 1); // Add 1 day
        dayAfterTomorrow.setDate(today.getDate() + 2); // Add 2 days

        console.log("Today:", today.toISOString());
        console.log("Tomorrow:", tomorrow.toISOString());
        console.log("Day After Tomorrow:", dayAfterTomorrow.toISOString());
        if (slottype === "Within 60 Mins") {
            setStarttimeSelected(today)
            setTimeFormat(null)
        } else if (slottype === "Tomorrow") {
            setStarttimeSelected(tomorrow)
            setTimeFormat(null)
        } else if (slottype === "Day after Tomorrow") {
            setStarttimeSelected(dayAfterTomorrow)
            setTimeFormat(null)
        }
    }, [slottype])

    const handleNameChange = (value) => {
        setName(value);
        handleValidation(value, "name");
        console.log(value)
        // setNameError(!event.target.value);
    };

    const handlePhoneChange = (value) => {
        // const phoneValue = event.target.value;
        const inputValue = value
            // Only allow digits (0-9) to be entered
            if ((!(/^\d*$/.test(inputValue)) || inputValue.length > 10)) {
            setPhone(removeLastCharacter(inputValue))
            return
        }
        setPhone(value);
        handleValidation(inputValue, "phone");
        // setPhoneError(!/^\d{10}$/.test(phoneValue)); // Validate phone number as 10 digits
    };

    const handleValidation = (inputValue, type) => {
        if (type === 'name') {
          // Simple name validation (only letters and spaces allowed)
          const nameRegex = /^[a-zA-Z\s]+$/;
          if (!nameRegex.test(inputValue) && inputValue ) {
            setNameError('Invalid name. Only letters and spaces are allowed.');
          } else {
            setNameError('');
          }
        } else if (type === 'phone') {
          // Simple phone number validation (only digits, length 10)
          const phoneRegex = /^[0-9]{10}$/;
          if (!phoneRegex.test(inputValue)) {
            setPhoneError('Invalid phone number. Must be 10 digits.');
          } else {
            setPhoneError('');
          }
        }
      };


    const removeLastCharacter = (str) => {
        return str.slice(0, -1);
    };

    // const handleSubmit = () => {
    //   if (!name) setNameError(true);
    //   if (!/^\d{10}$/.test(phone)) setPhoneError(true);
    //   if (!nameError && !phoneError && name && phone) {
    //     // Handle form submission
    //     alert('Booking Confirmed!');
    //   }
    // };
    const [Razorpay, isLoaded] = useRazorpay();
    const createOrder = async (params) => {
        const myHeaders = new Headers();
        setAPILoader(true)
        myHeaders.append("Content-Type", "application/json");
        let apiParams = {
            name: name || "NAME_NOT_FILLED",
            mobile: phone,
            "url": window.location.href,
            "tc_name": tcName,
            "tc_email": tcEmail,
            "tc_mobile": tcPhone,
            "city": city
         }
        if (step === 2) {
            apiParams = {
                ...apiParams,
                "slottype": slottype,
                "starttime": starttimeSelected,
                "city": city,
                "payment": true,
                "orderId": currentOrderId
            }
            delete apiParams["url"]
        }
        const raw = JSON.stringify(apiParams);
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        try {
            
            let response = await fetch(process.env.REACT_APP_NODE_BASE_URL + "/api/create_order_v2", requestOptions)
            response = response.text()
            setAPILoader(false)
            return response;
        } catch (error) {
            setAPILoader(false)
        }
    };

    const handlePayment = async () => {
        console.log("coming", step, name, phone, nameError, phoneError)
        // if (!name) {
        //     setNameError("Name is mandatory")
        // }
        if (!phone) {
            setPhoneError("Phone Number is mandatory")
        }
        // if (step === 2) {
        //     if (!city) {
        //         setCityError(true);
        //         return
        //     } else {
        //         setCityError(false)
        //     }
        // }
        if (phoneError || !phone || nameError) {
            return
        }

        window.dataLayer.push({ 'event': 'initiate_checkout' });
        let apiData = JSON.parse(await createOrder());
        console.log({apiData})
        if (apiData.status === 0) {
            setPhoneError(apiData.msg)
            return 
        }
        const { data: { grandTotal, orderId, secret } } = apiData;
        if ((urlCity || paramsCity)) {

            navigate("/new-booking/checkout?" + "phone=" + phone + "&orderId=" + orderId + "&city=" + (urlCity || paramsCity))
        } else {
            navigate("/new-booking/checkout?" + "phone=" + phone + "&orderId=" + orderId)
        }
        
        setPaymentStatus("pending")
        setCurrentOrderId(orderId)
        if (step === 1) {
            setStep(2)
            setName("")
            return
        }
        setPaymentStatus("success")
        // console.log({ order, name, phone })
        return
        const options: RazorpayOptions = {
            key: secret,
            order_id: orderId,
            amount: grandTotal,
            currency: "INR",
            name: name || "",
            description: slottype,
            image: "https://www.kynohealth.com/images/Kyno-footer-logo.png",
            handler: (res) => {
                if (res.razorpay_payment_id) {
                    setPaymentStatus('pending');
                } else {
                    setPaymentStatus('failure');
                }
                console.log(res);
                setOpenPopup(true);
            },
            modal: {
                ondismiss: function () {
                    console.log("hello closing")
                    setPaymentStatus('failure');
                    setOpenPopup(true);
                },
            },
            prefill: {
                name: name || "",
                email: "aditya@kynohealth.com",
                contact: phone,
            },
            notes: {
                address: "HUAMI TECHNOLOGIES PRIVATE LIMITED, H NO M 37, THIRD FLOOR, MAYFIELD GARDEN, SECTOR 52, OPPOSITE ARTEMIS HOSPITAL, GURGAON, Gurgaon, Haryana, India, 122001",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    };

    const resetPayment = () => {
        setPaymentStatus('pending');
        setOpenPopup(false);
    };

    const handleTimeSelection = ({ selectedHour, selectedMinute, selectedPeriod }) => {
        const isoString = convertToISOTime(`${selectedHour}:${selectedMinute} ${selectedPeriod}`, slottype);
        setTimeFormat(convertToCustomFormat(`${selectedHour}:${selectedMinute} ${selectedPeriod}`, slottype))
        console.log({ isoString })
        setStarttimeSelected(isoString)
    }

    useEffect(() => {
        if (paymentStatus === "success") {
            window.location.href = (isPlumPage ? '/booking/plum-success?bookedSlot=' : '/booking/confirmation');
            // navigate('/booking/confirmation?bookedSlot=' + starttimeSelected);
        }
    }, [paymentStatus])

    // Truecaller integration

    // Function to start the Truecaller flow and set requestNonce
    const handleInputClick = () => {
        console.log({ android })
        if (!android || isTruecallerOpened) return
        const nonce = generateRequestNonce();  // Generate a unique nonce
        setRequestNonce(nonce);
        setIsPolling(true); // Start polling
        initiateTruecallerFlow(nonce);  // Redirect to Truecaller SDK
        setTruecallerOpened(true)
    };

    // Function to generate a unique nonce
    const generateRequestNonce = () => {
        return 'nonce_' + Math.random().toString(36).substr(2, 9);
    };

    // Function to initiate Truecaller SDK flow
    const initiateTruecallerFlow = (nonce) => {
        const partnerKey = 'j7jJnfdd002127115425894a9a16500489778';
        const partnerName = 'Kynohealth.com';
        const privacyUrl = 'https://www.kynohealth.com/privacy-policy/'; // Link to your privacy policy
        const termsUrl = 'https://www.kynohealth.com/terms-conditions/';     // Link to your terms of 
        const language = 'en';
        const ttl = 600000;

        const truecallerUrl = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${nonce}&partnerKey=${partnerKey}&partnerName=${partnerName}&lang=${language}&privacyUrl=${encodeURIComponent(privacyUrl)}&termsUrl=${encodeURIComponent(termsUrl)}&ttl=${ttl}`;

        window.location = truecallerUrl;
    };

    // Poll the Truecaller status API every 3 seconds
    useEffect(() => {
        if (isPolling && requestNonce && pollingCount < maxPollingAttempts) {
            const interval = setInterval(() => {
                pollTruecallerStatus(requestNonce);
            }, 1500);  // Poll every 3 seconds

            return () => clearInterval(interval);  // Clear the interval after polling stops
        }
    }, [isPolling, requestNonce, pollingCount]);

    // Function to poll Truecaller status API
    const pollTruecallerStatus = async (nonce) => {
        try {
            const response = await axios.post(process.env.REACT_APP_NODE_BASE_URL + '/api/truecaller-status', { requestId: nonce })
            console.log({ response })
            const { status, data } = response.data;

            if (status === 'user_verified') {
                setIsPolling(false);  // Stop polling
                console.log('User profile data:', data);
                const { name: { first, last }, onlineIdentities: { email }, phoneNumbers } = data
                setName((first + " " + last)?.trim() || name)
                setTcName((first + " " + last)?.trim())
                setPhone(phoneNumbers.length ? String(phoneNumbers[0]).slice(-10) : phone)
                setTcPhone(phoneNumbers.length ? String(phoneNumbers[0]).slice(-10) : '')
                setTcEmail(email)

            } else if (status === 'user_rejected') {
                setIsPolling(false);  // Stop polling
                console.log('User rejected the flow.');
            } else if (status === 'flow_invoked') {
                setPollingCount(prev => prev + 1);  // Continue polling
            } else {
                setIsPolling(false);
                console.log('something went wrong with status 200');
            }
        } catch (error) {
            setPollingError('Error fetching Truecaller status');
            console.error('Polling error:', error);
            setIsPolling(false);  // Stop polling on error
        }

        // Stop polling after max attempts
        if (pollingCount >= maxPollingAttempts) {
            setIsPolling(false);
            console.log('Max polling attempts reached');
        }
    };


    const handleButtonClick = (buttonName) => {
        if (buttonName === "Schedule visit") {
          setSlotType("Tomorrow")
        } else if (buttonName === "Within 60 Mins") {
          setSlotType(buttonName)
        } else {
          setSlotType(buttonName)
        }
      };

    // useEffect(() => {
    //   if (isLoaded) {
    //     handlePayment();
    //   }
    // }, [isLoaded, handlePayment])

    let srNumberMapping = {
        'bangalore': '01206056619',
        'delhi': '01206056620',
        'delhi-ncr': '01206056620',
        default:'01206056620'
    }
    console.log({ urlCity })
    return (
        <div>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

                <title>Medical Consultation | Best Physician Doctor in Noida</title>
                <meta name="description" content="Looking for a skilled general physician in Noida? Our experienced doctors provide expert medical consultation services and personalized treatment." />
                <link rel="canonical" href="https://www.kynohealth.com/" />

                <meta property='og:image' content={logo} />
                {isPlumPage ? <meta name="robots" content="noindex, nofollow" /> : ''}
                <meta name="google-site-verification" content="EADXQCFm27CfkWKQlFSbbLUSV6Q1I-yh-8taT0qxR-s" />
            </Helmet>
            <Nav isBookSlot={true} isPaidPage={true} />
            <div className='booking_slot_new_flow' style={{ backgroundColor: "#f9f9f9", width: '100%', maxWidth: "500px", margin: '0px auto', paddingTop: '50px 0', height: contentHeight }}>
                <SlickCarousel />
                {step === 2 && <ConsultationPrice />}
                {(<>
                {/* <Accordion defaultExpanded>
                    <AccordionDetails> */}
                        <div className='input_custom_wrapper' style={{ marginBottom: "20px", width: "300px", gap: "10px" , display:"flex", justifyContent: "space-between", alignItems:"center", flexWrap: "wrap"}}>
                            {<h2><b>Book your home visit now!</b></h2>}
                            <TextField
                                variant="outlined"
                                fullWidth
                                hidden={step !== 1}
                                placeholder="Enter your phone number"
                                value={phone}
                                onChange={(e) => handlePhoneChange(e.target.value)}
                                onClick={() => handleInputClick()}
                                error={!!phoneError}
                                helperText={phoneError}
                                // onClick={() => handleInputClick()}
                                InputProps={{
                                    sx: {
                                        padding: "0px",
                                        backgroundColor: '#f7f7f7',
                                        borderRadius: '10px',
                                        width: "auto",
                                        border: "1px solid #e0e0e0",
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: phoneError ? 'red' : 'transparent',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: phoneError ? 'red' : 'transparent',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: phoneError ? 'red' : 'transparent',
                                        },
                                        '& .MuiInputBase-input': {
                                            color: '#424242',
                                            fontSize: '14px',
                                            padding: '10px'
                                        },
                                    },
                                }}
                            />
                            <TextField
                                variant="outlined"
                                fullWidth
                                placeholder="Enter your name (optional)"
                                value={name}
                                hidden={step === 1}
                                onChange={(e) => handleNameChange(e.target.value)}
                                onClick={() => handleInputClick()}
                                error={!!nameError}
                                helperText={nameError}
                                // onClick={() => handleInputClick()}
                                InputProps={{
                                    sx: {
                                        backgroundColor: '#f7f7f7',
                                        borderRadius: '10px',
                                        width: "auto",
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: nameError ? 'red' : 'transparent',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: nameError ? 'red' : 'transparent',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: nameError ? 'red' : 'transparent',
                                        },
                                        '& .MuiInputBase-input': {
                                            color: '#424242',
                                            fontSize: '14px',
                                            padding: '10px'
                                        },
                                    },
                                }}
                            />
                
                            {/* {error && (
                        <Typography
                          variant="body2"
                          sx={{ color: 'red', marginTop: '5px', marginLeft: '10px' }}
                        >
                          {error}
                        </Typography>
                      )} */}
                      <>
                                <FormControl hidden={!!paramsCity || step === 1} fullWidth sx={{ margin: '10px 0', backgroundColor: '#f7f7f7',
                                            borderRadius: '10px',
                                            width: "100%",
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '& .MuiInputBase-input': {
                                                color: '#424242',
                                                fontSize: '14px',
                                                padding: '10px'
                                            } }} error={cityError}>
                                    <Select style={{ borderRadius: "10px" }} value={city} onChange={(e) => {
                                        setCity(e.target.value)
                                        setCityError(false)
                                    }} displayEmpty
                                    >
                                        <MenuItem value="" style={{ padding: '10px 0'}}  disabled selected hidden>
                                            <em>Choose your city (optional)</em>
                                        </MenuItem>
                                        <MenuItem value="delhi">Delhi</MenuItem>
                                        <MenuItem value="bangalore">Bangalore</MenuItem>
                                        <MenuItem value="gurgaon">Gurgaon</MenuItem>
                                        <MenuItem value="noida">Noida</MenuItem>
                                        <MenuItem value="ghaziabad">Ghaziabad</MenuItem>
                                    </Select>
                                </FormControl></>
                                <><FormControl hidden={step === 1} fullWidth  sx={{ margin: '0px 0 10px', backgroundColor: '#f7f7f7',
                                            borderRadius: '10px',
                                            width: "100%",
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '& .MuiInputBase-input': {
                                                color: '#424242',
                                                fontSize: '14px',
                                                padding: '10px'
                                            } }}>
                                    <Select style={{ borderRadius: "10px" }} value={slottype} onChange={(e) => {
                                        handleButtonClick(e.target.value)
                                        // setCity(e.target.value)
                                        // setCityError(false)

                                    }} displayEmpty  >
                                        <MenuItem value="" disabled selected hidden>
                                            <em>Choose your visit time (optional)</em>
                                        </MenuItem>
                                        <MenuItem value="Within 60 Mins">Within 60 Mins</MenuItem>
                                        <MenuItem value="Schedule for later">Schedule for later</MenuItem>
                                        {/* <MenuItem value="Day after Tomorrow">Day after Tomorrow</MenuItem> */}
                                    </Select>
                                </FormControl></>
                        
                            {/* <LabelComponent hidden={step === 1} text="When you want to schedule you visit?" />
                            <CustomButtonGroup hidden={step === 1} setBookActive={setBookActive} setSlotType={setSlotType} slottype={slottype} /> */}
                            <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{
                                        height: "40px",
                                        width: "auto",
                                        fontWeight: "600",
                                        padding: "10px 15px",
                                        gap: "8px",
                                        borderRadius: "10px",
                                        fontSize: "12px",
                                        background: "#FB9F40"
                                    }}
                                    disabled={APILoader}
                                    onClick={(e) => !APILoader && handlePayment(e)}
                                >
                                    {step === 1 ? "Continue" : "Confirm Booking"}
                                </Button>
                            <div style={{ textAlign: "center" }}>
                            </div>
                
                        </div >
                        {step === 1 && <Feedback slidesPerViewDesktop={1} slidesPerViewMobile={1} autoscroll={false} />}
                            {/* <CustomButtonGroup setBookActive={setBookActive} setSlotType={setSlotType} slottype={slottype} />
                            {bookActive && <BookingDetails handleTimeSelection={handleTimeSelection} slotType={slottype} timeFormat={timeFormat} />} */}
                        {/* </AccordionDetails>
                    </Accordion> */}
                    {/* <div style={{ textAlign: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{
                                margin: "0 auto",
                                marginTop: '20px',
                                maxWidth: "343px",
                                height: "46px",
                                fontWeight: "600",
                                padding: "12px 16px 12px 16px",
                                gap: "8px",
                                borderRadius: "10px",
                                background: (step === 1 ? (nameError || phoneError || !name || !phone) : !city) ? "#dcdcdc" : "#FB9F40"
                            }}
                            disabled={step === 1 ? (nameError || phoneError || !name || !phone) : !city}
                            onClick={() => handlePayment()}
                        >
                            {step === 1 ? "Continue" : "Confirm Booking"}
                        </Button>
                    </div> */}
                    <PaymentStatusPopup open={openPopup} onClose={() => setOpenPopup(false)} orderId={currentOrderId} paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus} /></>)}
            </div>
            { <FloatingCallButton customSrNumber={customSrNumber ? customSrNumber : urlCity ? srNumberMapping[urlCity] || srNumberMapping['default'] : srNumberMapping['default']} text={"Call our Experts"} />}
            {/* <Footer isHideSticky={true} isPaidPage={true} /> */}
        </div>
    );
};


function getLabelBasedOnTime() {
    const now = new Date();
    const hours = now.getHours(); // Get current hour in 24-hour format
  
    if (hours >= 19 || hours < 7) {
      return 'night'; // Show this between 9 PM to 9 AM
    } else {
      return "day"; // Show this between 9 AM to 9 PM
    }
  }

const ConsultationPrice = () => {
    return (
        <blockquote>
            
        
      <div className="consultation-price">
        <p>
          <b>Book now for <span style={{ color: "#003c3c" }}>free</span>, pay after doctor visit!</b>
            <div style={{ marginTop: "5px"}}>Consultation Price:{" "}
                {getLabelBasedOnTime() === "night" ?<><span className="old-price">₹1999</span>{" "}
                <span className="new-price">₹1499</span></> : <><span className="old-price">₹1499</span>{" "}
                <span className="new-price">₹999</span></>}
            </div>
        </p>
      </div>
      </blockquote>
    );
  };

  const features = [
    {
      icon: "/images/zap-fast.svg",
      title: "Fastest Service",
      description: "In India",
    },
    {
      icon: "/images/clock-plus.svg",
      title: "Highest Quality",
      description: "Assured",
    },
    {
      icon: `/images/appointmentbooking.svg`,
      title: "Detailed Consultation",
      description: "30+ Minutes",
    },
  ];
  
  const FeatureList = () => {
    return (
      <div className="feature-list">
        {features.map((feature, index) => (
          <div key={index} className="feature-item">
            <div className="feature-icon"><img src={feature.icon} /> </div>
            <div className="feature-text">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };


function convertToISOTime(timeString, day = "today") {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');

    if (modifier === 'PM' && hours !== '12') {
        hours = parseInt(hours, 10) + 12;
    }
    if (modifier === 'AM' && hours === '12') {
        hours = '00';
    }
    const currentDate = new Date();
    if (day === 'Tomorrow') {
        currentDate.setDate(currentDate.getDate() + 1);
    } else if (day === 'Day after Tomorrow') {
        currentDate.setDate(currentDate.getDate() + 2);
    }

    currentDate.setHours(hours, minutes, 0, 0);

    return currentDate.toISOString();
}

function convertToCustomFormat(timeString, day = 'today') {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');

    if (modifier === 'PM' && hours !== '12') {
        hours = parseInt(hours, 10) + 12;
    }
    if (modifier === 'AM' && hours === '12') {
        hours = '00';
    }

    const currentDate = new Date();

    if (day === 'Tomorrow') {
        currentDate.setDate(currentDate.getDate() + 1);
    } else if (day === 'Day after Tomorrow') {
        currentDate.setDate(currentDate.getDate() + 2);
    }

    let period;
    const hour = parseInt(hours, 10);

    if (modifier === 'AM') {
        period = hour < 12 ? 'morning' : 'afternoon';
    } else {
        if (hour < 17) {
            period = 'afternoon';
        } else if (hour < 20) {
            period = 'evening';
        } else {
            period = 'night';
        }
    }

    // Format the date part without changing the time zone
    const dayOfMonth = currentDate.getDate();
    const month = currentDate.toLocaleString('en-US', { month: 'long' });
    const suffix = dayOfMonth === 1 ? 'st' : dayOfMonth === 2 ? 'nd' : dayOfMonth === 3 ? 'rd' : 'th';

    const formattedTime = `${time.replace(':', ' : ')} ${modifier} in the ${period} of ${dayOfMonth}${suffix} ${month}`;

    return formattedTime;
}

const FloatingCallButton = ({customSrNumber, text}) => {

    const handleCallClick = () => {
      // Handle the call action here
      alert("Call button clicked!");
    };
  
    let defaultStyle =
      text ? {
          fontSize: "11px", background: "#FB9F40", width: "125px", height: "30px", color: "white", borderRadius: "8px", justifyContent: "center", gap: "3px",textTransform: "unset", minHeight: "27px", fontWeight: "600", opacity: 0.7
      } : {
      }
  
    return (
      <a href={`tel:${customSrNumber}`} style={{
        color: 'inherit', textDecoration: 'none'
      }}>
        <Fab
          // color="rgb(251, 159, 64)" 
          sx={{
            position: 'fixed',
            bottom: '30px',
            right: '10px',
            zIndex: 1000,
            background: 'rgb(251, 159, 64)',
            ...defaultStyle
          }}
          className={`newcontent_floating`}
        >
          <PhoneIcon sx={{
            width: "20px"
          }} />
          {text ? " " + text : null }
        </Fab>
      </a>
    );
  };


const CustomNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          background: "white",
          borderRadius: "50%",
          padding: "0 5px 0 0px",
          position: "absolute",
          right: "10px", // Positioned inside the slider
          top: "50%", // Vertically center
          transform: "translateY(-50%)", // Correct vertical alignment
          zIndex: 1,
          opacity: 0.5
        }}
        onClick={onClick}
      />
    );
  };
  
  const CustomPrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          background: "white",
          borderRadius: "50%",
          padding: "0 0px 0 3px",
          position: "absolute",
          left: "10px", // Positioned inside the slider
          top: "50%", // Vertically center
          transform: "translateY(-50%)", // Correct vertical alignment
          zIndex: 1,
          opacity: 0.5
        }}
        onClick={onClick}
      />
    );
  };


const SlickCarousel = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // You can change the breakpoint as needed
        };

        // Initial check
        handleResize();

        // Listen for window resize
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        dots: false, // Removes the dots
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enables auto-scroll
        autoplaySpeed: 3000, // Slide change interval (3 seconds)
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };
    const isPlumPage = !!window.location.href.includes("plum")
    return (
        <div className='carousel-container'>
            {isPlumPage ? <Slider {...settings}>
                <div>
                    <img src="/images/kh_desktop6.jpg" alt="slide 4" />
                </div>
            </Slider> :
                <Slider {...settings}>
                    <div>
                        <img src="/images/B1.jpg" alt="slide 1" />
                    </div>
                    {/* <div>
                        <img src="/images/6jan2new-booking.png" alt="slide 2" />
                    </div> */}
                    {/* <div>
                        <img src="/images/3.2.jpg" alt="slide 1" />
                    </div> */}
                    <div>
                        <img src="/images/4.2.jpg" alt="slide 2" />
                    </div>
                    <div>
                        <img src="/images/5.2.jpg" alt="slide 1" />
                    </div>
                    <div>
                        <img src="/images/B2.jpg" alt="slide 2" />
                    </div>
                </Slider>
            }
        </div>
    );
};

// const timeString = "03:08 PM";
// const isoString = convertToISOTime(timeString);
// console.log(isoString);

// Example usage
// const utcDateString = '2024-08-15T17:45:37.649Z';
// const localFormattedDate = convertToLocalTime(utcDateString);

export default BookingFormNewFlow;
