import React from "react";
import Header from '../Header/index';
import PatientCare from '../PatientCare/index';
import ServiceList from '../ServiceList/index';
import Faqs from '../Faqs/index';
import Booking from '../Booking/index';
import DownloadApp from '../DownloadApp/index';
import Footer from '../Footer/index';

function ProvideServices() {
  return (
    <div>
      <Header />
      <PatientCare />
      <ServiceList />
      <Faqs />
      {/* <Booking /> */}
      {/* <DownloadApp /> */}
      <Footer />
    </div>
  );
}

export default ProvideServices;