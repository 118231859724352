import React, { useState } from "react";
import "../DoctorConsult/CSS/style.scss";
import close from '../DoctorConsult/Images/Close.png';
import sheetFever from '../DoctorConsult/Images/sheetFever.svg';
import sheetWeakness from '../DoctorConsult/Images/sheetWeakness.svg';
import stomachAcheSheet from '../DoctorConsult/Images/stomachAcheSheet.svg';
import bpSheet from '../DoctorConsult/Images/bpSheet.svg';
import generalSheet from '../DoctorConsult/Images/generalSheet.svg';
import diabetesSheet from '../DoctorConsult/Images/diabetesSheet.svg';
import diarrheaSheet from '../DoctorConsult/Images/diarrheaSheet.svg';
import bodyAcheSheet from '../DoctorConsult/Images/bodyAcheSheet.svg';

import Allergies_D from '../DoctorConsult/Images/Allergies_D.svg';
import Animal_bites_D from '../DoctorConsult/Images/Animal_bites_D.svg';
import Breathing_difficulty_D from '../DoctorConsult/Images/Breathing_difficulty_D.svg';
import Dengue_D from '../DoctorConsult/Images/Dengue_D.svg';
import Malaria_D from '../DoctorConsult/Images/Malaria_D.svg';
import Muscle_pain_D from '../DoctorConsult/Images/Muscle_pain_D.svg';
import Thyroid_D from '../DoctorConsult/Images/Thyroid_D.svg';
import Typhoid_D from '../DoctorConsult/Images/Typhoid_D.svg';


const DoctorConsult = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleSheet = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className="health-concerns">
      <div className="doctor-header">

        <div className="header-content">
          <h2>Consult top doctors for general health concerns</h2>
        </div>
      </div>

      <div className="scrollable-cards">
        <img src={sheetFever} alt="Fever" className="health_problems"/>

        {/* <img src={cough} alt="Cough" className="health_problems"/> */}
        <img src={sheetWeakness} alt="Weakness" className="health_problems"/>
        
        <img src={bodyAcheSheet} alt="Stomach Ache" className="health_problems"/>

        {/* <img src={breathing} alt="Breathing" className="health_problems"/> */}

        <img src={generalSheet} alt="General" className="health_problems"/>
        <img src={stomachAcheSheet} alt="Stomach Ache" className="health_problems"/>
        <img src={diarrheaSheet} alt="Stomach Ache" className="health_problems"/>
        {/* <img src={stomachAche} alt="Stomach Ache" className="health_problems"/> */}

        {/* <img src={sheetFever} alt="Fever" className="health_problems"/> */}
        <img src={bpSheet} alt="Blood Pressure" className="health_problems"/>
        <img src={diabetesSheet} alt="Diabetes" className="health_problems"/>
        <img src={Thyroid_D} alt="Diabetes" className="health_problems"/>
        <img src={Muscle_pain_D} alt="Diabetes" className="health_problems"/>
        <img src={Breathing_difficulty_D} alt="Diabetes" className="health_problems"/>

        <img src={Animal_bites_D} alt="Diabetes" className="health_problems"/>
        <img src={Allergies_D} alt="Diabetes" className="health_problems"/>
        <img src={Typhoid_D} alt="Diabetes" className="health_problems"/>
        <img src={Dengue_D} alt="Diabetes" className="health_problems"/>
        <img src={Malaria_D} alt="Diabetes" className="health_problems"/>

     </div>

      <div className="view-all">
        <button className="view-all-btn" onClick={toggleSheet}>View all</button>
        {isVisible ?
          <div className={`consult_bottom-sheet ${isVisible ? 'open' : ''}`}>
            <div className="sheet-content">
              <div className="sheet-header">
                <span className="sheet_title">All Health Concerns</span>
                <button className="close-btn" onClick={toggleSheet}>
                  <img src={close} alt="" />
                </button>
              </div>

              <div className="health-grid">
                {/* Grid Items */}

                <img src={sheetFever} alt="Fever" className="health_problems"/>

        {/* <img src={cough} alt="Cough" className="health_problems"/> */}
        <img src={sheetWeakness} alt="Weakness" className="health_problems"/>
        
        <img src={bodyAcheSheet} alt="Stomach Ache" className="health_problems"/>

        {/* <img src={breathing} alt="Breathing" className="health_problems"/> */}

        <img src={generalSheet} alt="General" className="health_problems"/>
        <img src={stomachAcheSheet} alt="Stomach Ache" className="health_problems"/>
        <img src={diarrheaSheet} alt="Stomach Ache" className="health_problems"/>
        {/* <img src={stomachAche} alt="Stomach Ache" className="health_problems"/> */}

        {/* <img src={sheetFever} alt="Fever" className="health_problems"/> */}
        <img src={bpSheet} alt="Blood Pressure" className="health_problems"/>
        <img src={diabetesSheet} alt="Diabetes" className="health_problems"/>
        <img src={Thyroid_D} alt="Diabetes" className="health_problems"/>
        <img src={Muscle_pain_D} alt="Diabetes" className="health_problems"/>
        <img src={Breathing_difficulty_D} alt="Diabetes" className="health_problems"/>

        <img src={Animal_bites_D} alt="Diabetes" className="health_problems"/>
        <img src={Allergies_D} alt="Diabetes" className="health_problems"/>
        <img src={Typhoid_D} alt="Diabetes" className="health_problems"/>
        <img src={Dengue_D} alt="Diabetes" className="health_problems"/>
        <img src={Malaria_D} alt="Diabetes" className="health_problems"/>

              </div>

            </div>
          </div> : ''}

      </div>


    </div>
  );
};

export default DoctorConsult;
