import React from "react";
import "../PatientCare/Css/style.css";

function PatientCare() {
    return (
        <div className="patient_card">
            <h1 className="patient_card-title">
            Expert Care<br/>
            right where you need it
            </h1>
            <p className="patient_card-description">
            Explore our range of at home healthcare services<br/>
            made more accessible and convenient for you
            </p>
            <a href="tel:9953104104">
            <div className="patient_card-availability">
                Call at 9953104104
                {/* Available in <span className="cities">Delhi NCR & Bangalore</span> */}
            </div>
            </a>
        </div>
    );
}

export default PatientCare;
