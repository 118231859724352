import React from "react";
import "./Css/style.scss";
// import patientInteraction from "./Images/patientInteraction.png"; // Replace with the actual path of the image
import patientInteractionDesktop from './Images/Aboutus banner_final.png';

const WhyKyno = () => {
  return (
    <div className="kyno_container">
      {/* Image Section */}
      <div className="kyno-image-section">
        <img
          src={patientInteractionDesktop}
          alt="Doctor consulting a couple mobile"
          className="kyno-image-mobile"
        />
        <img
          src={patientInteractionDesktop}
          alt="Doctor consulting a couple desktop"
          className="kyno-image-desktop"
        />
      </div>
    </div>
  );
};

export default WhyKyno;
