import React from "react";
import "../ServiceList/Css/style.css";
import serviceImageMobile1 from '../ServiceList/Images/serviceImage.png';
import serviceImageMobile2 from '../ServiceList/Images/serviceImage2.png';
import serviceImageMobile3 from '../ServiceList/Images/serviceImage3.png';
import serviceImageMobile4 from '../ServiceList/Images/serviceImage4.png';
import serviceImageMobile5 from '../ServiceList/Images/serviceImage5.png';
import serviceImageMobile6 from '../ServiceList/Images/serviceImage6.png';

import servicePointer from '../ServiceList/Images/servicePointer.png';

function ServiceList() {
    let contentJSON = [
        {
            image: serviceImageMobile1,
            h1: "Doctor at home",
            sub: 'Get access to qualified and professional doctors in the comfort of your home. Book a medical  consultation and receive expert health right at your doorstep.',
            points: [
                'Get expert medical advice and treatment at home',
                'Access urgent medical care for non-critical health concerns',
                'Receive prescriptions conveniently from home'
            ]
        },
        {
            image: serviceImageMobile2,
            h1: "Nursing At Home",
            sub: 'Receive professional nursing care tailored to your specific needs, ensuring recovery and comfort. From wound care to post-surgical assistance, our skilled nurses provide compassionate support right in the comfort of your home.',
            points: [
                'Tailored care plans focused on comfort and dignity.',
                'Receive skilled assistance for post-surgery recovery or chronic conditions.',
                'Ongoing monitoring and assistance from the comfort of your home.'
            ]
        },
        {
            image: serviceImageMobile3,
            h1: "Diagnostic Support",
            sub: 'Get essential diagnostic services at your doorstep for accurate and timely health assessments. Our professionals ensure safe, reliable sample collection and comprehensive reports for all your health needs.',
            points: [
                'Samples collected from your home with utmost care.',
                'Accurate and quick results for a variety of health needs.',
                'Fully compliant with the highest quality standards.'
            ]
        },
        {
            image: serviceImageMobile4,
            h1: "Elderly Care Made Convinient",
            sub: 'Empathetic and specialized care designed to support the health and wellbeing of seniors. Our trained caregivers and medical professionals bring comfort, dignity, and safety to your loved ones at home.',
            points: [
                'Tailored care plans for ongoing health management.',
                'Support for long-term medical needs.',
                'Professional care that brings convenience to you and comfort to your loved ones at home.'
            ]
        },
        {
            image: serviceImageMobile5,
            h1: "Chronic Disease Management",
            sub: 'Manage chronic conditions effectively without leaving the comfort of your home. Our expert team provides personalized care, regular monitoring, and ongoing support to help you live a healthier life.',
            points: [
                'Comprehensive assessments to manage your condition better.',
                'Specialized support tailored care for diabetes, hypertension, and other chronic illnesses.',
                'Reduce hospital visits while staying on top of your health.'
            ]
        },
        {
            image: serviceImageMobile6,
            h1: "Injections At Home",
            sub: 'Experience safe and hygienic injection services delivered by qualified professionals at home. Whether routine or urgent, our team ensures timely and comfortable administration of injections for all your needs.',
            points: [
                'Administered by trained professionals with utmost hygiene.',
                'Avoid the hassle of travel for routine injections.',
                'Available when you need it, in the comfort of your space.'
            ]
        }
    ]
    return (
        <>
            {contentJSON.map((item, i) => {
                return (
                    <div key={i} className={`doctor-card ${i%2 === 0 ? 'doctor-card-even' : ''}`}>

                        {/* Image Section */}
                        <div className="doctor-image" style={{ backgroundImage: `url(${item.image})`}}>
                            {/* <img
                                src={item.image} // Replace with your image URL
                                alt={item.h1}
                                className="doctor-image-desktop"
                            /> */}
                        </div>

                        {/* Content Section */}
                        <div className="doctor-content">
                            <h1 className="doctor-title">{item.h1}</h1>
                            <p className="doctor-description">
                                {item.sub}
                            </p>

                            {/* Feature List */}
                            <ul className="doctor-features">
                                {item.points.map((p, j) =><li key={j}>
                                    <img src={servicePointer} alt="" /> {p}
                                </li>)}
                            </ul>

                            {/* Availability */}
                            {/* <div className="availability">
        Available in <span>Delhi NCR & Bangalore</span>
    </div> */}

                            {/* Book Button */}
                            {/* <button className="book-button">Book a home visit</button> */}
                        </div>




                    </div>
                )
            })}
        </>
    );
}

export default ServiceList;
