import React, { useEffect, useState } from 'react';
// import CustomTextField from './CustomTextField';
import { Button, TextField, Typography } from '@mui/material';
import PaymentStatusPopup from '../booking/PaymentStatusPopup';
import useRazorpay from 'react-razorpay';
import LabelComponent from '../booking/labelComponent';
import axios from 'axios';

const InputWithLabel = ({ city, newCityPage, newContent }) => {
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [slottype, setSlotType] = useState("Within 60 Mins")
    const [currentOrderId, setCurrentOrderId] = useState(null)
    const [openPopup, setOpenPopup] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('pending');
    const [starttimeSelected, setStarttimeSelected] = useState(null)
    const [requestNonce, setRequestNonce] = useState(null);
    const [isPolling, setIsPolling] = useState(false);
    const [pollingCount, setPollingCount] = useState(0);
    const [pollingError, setPollingError] = useState(null);
    const [tcName, setTcName] = useState('');
    const [tcPhone, setTcPhone] = useState('');
    const [tcEmail, setTcEmail] = useState('');
    const [name, setName] = useState('Google') 
    const maxPollingAttempts = 5;
    // const name = "Google"
    let android = /Android/.test(navigator.userAgent) && !window.MSStream;
    // const [error, setPhoneError] = useState(error);
    useEffect(() => {
        if (slottype === "Within 60 Mins") {
            setStarttimeSelected(new Date().toISOString())
        }
    }, [slottype])

    useEffect(() => {
        if (paymentStatus === "success") {
          window.location.href = '/booking/confirmation?bookedSlot=' + starttimeSelected;
      // navigate('/booking/confirmation?bookedSlot=' + starttimeSelected);
    }
      }, [paymentStatus])

    const createOrder = async (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "name": name,
            "mobile": phone,
            "slottype": slottype,
            "starttime": starttimeSelected,
            "city": city,
            "url": window.location.href
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        let response = await fetch(process.env.REACT_APP_NODE_BASE_URL + "/api/create_order", requestOptions)
        response = response.text()
        return response;
    };

    const [Razorpay, isLoaded] = useRazorpay();
    const handlePayment = async (e) => {
        e.preventDefault();
        if (!phone) {
            setPhoneError("Phone Number is mandatory")
        }
        if (phoneError || !phone) {
            return
        }
        if (newCityPage) {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");


            const raw = JSON.stringify({
            "city": city,
            "mobile": tcPhone || phone,
            "pageurl": window.location.href,
            name: tcName || name
            });

            const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
            };
            await fetch("https://www.kynohealth.com/public/addNearMeLeads", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
            let currentUrl = new URL(window.location.href);

            // Determine the base URL for concatenation
            let baseUrl = currentUrl.origin + currentUrl.pathname + "-checkout";

            // Preserve existing query parameters and append the phone parameter
            let newUrl = new URL(baseUrl);
            currentUrl.searchParams.forEach((value, key) => {
            newUrl.searchParams.append(key, value);
            });
            newUrl.searchParams.append("phone", phone);

            // Redirect to the new URL
            window.location.href = newUrl.toString();
            return
        }
        window.dataLayer.push({ 'event': 'initiate_checkout' });
        const { data: { grandTotal, orderId, secret } } = JSON.parse(await createOrder());
        // console.log({ order, name, phone })
        setPaymentStatus("pending")
        setCurrentOrderId(orderId)
        const options: RazorpayOptions = {
            key: secret,
            order_id: orderId,
            amount: grandTotal,
            currency: "INR",
            name: name,
            description: slottype,
            image: "https://www.kynohealth.com/images/Kyno-footer-logo.png",
            handler: (res) => {
                if (res.razorpay_payment_id) {
                    setPaymentStatus('pending');
                } else {
                    setPaymentStatus('failure');
                }
                console.log(res);
                setOpenPopup(true);
            },
            modal: {
                ondismiss: function () {
                    console.log("hello closing")
                    setPaymentStatus('failure');
                    setOpenPopup(true);
                },
            },
            prefill: {
                name: name,
                email: "aditya@kynohealth.com",
                contact: phone,
            },
            notes: {
                address: "HUAMI TECHNOLOGIES PRIVATE LIMITED, H NO M 37, THIRD FLOOR, MAYFIELD GARDEN, SECTOR 52, OPPOSITE ARTEMIS HOSPITAL, GURGAON, Gurgaon, Haryana, India, 122001",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    };

    const handleValidation = (inputValue) => {
        // Simple phone number validation (only digits, length 10)
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(inputValue)) {
            setPhoneError('Invalid phone number. Must be 10 digits.');
        } else {
            setPhoneError('');
        }
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        // Only allow digits (0-9) to be entered
        if ((!(/^\d*$/.test(inputValue)) || inputValue.length > 10)) {
            setPhone(removeLastCharacter(inputValue))
            return
        }
        setPhone(inputValue)
        handleValidation(inputValue);
    };

    const removeLastCharacter = (str) => {
        return str.slice(0, -1);
    };


// Truecaller integration

// Function to start the Truecaller flow and set requestNonce
const handleInputClick = () => {
    console.log({ android })
    if (!android) return
    const nonce = generateRequestNonce();  // Generate a unique nonce
    setRequestNonce(nonce);
    setIsPolling(true); // Start polling
    initiateTruecallerFlow(nonce);  // Redirect to Truecaller SDK
  };
  
  // Function to generate a unique nonce
  const generateRequestNonce = () => {
    return 'nonce_' + Math.random().toString(36).substr(2, 9);
  };
  
  // Function to initiate Truecaller SDK flow
  const initiateTruecallerFlow = (nonce) => {
    const partnerKey = 'j7jJnfdd002127115425894a9a16500489778';
    const partnerName = 'Kynohealth.com';
    const privacyUrl = 'https://www.kynohealth.com/privacy-policy/'; // Link to your privacy policy
    const termsUrl = 'https://www.kynohealth.com/terms-conditions/';     // Link to your terms of 
    const language = 'en';
    const ttl = 600000;
  
    const truecallerUrl = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${nonce}&partnerKey=${partnerKey}&partnerName=${partnerName}&lang=${language}&privacyUrl=${encodeURIComponent(privacyUrl)}&termsUrl=${encodeURIComponent(termsUrl)}&ttl=${ttl}`;
  
    window.location = truecallerUrl;
  };
  
  // Poll the Truecaller status API every 3 seconds
  useEffect(() => {
    if (isPolling && requestNonce && pollingCount < maxPollingAttempts) {
      const interval = setInterval(() => {
        pollTruecallerStatus(requestNonce);
      }, 1500);  // Poll every 3 seconds
  
      return () => clearInterval(interval);  // Clear the interval after polling stops
    }
  }, [isPolling, requestNonce, pollingCount]);
  
  // Function to poll Truecaller status API
  const pollTruecallerStatus = async (nonce) => {
    try {
      const response = await axios.post(process.env.REACT_APP_NODE_BASE_URL + '/api/truecaller-status', { requestId: nonce })
      console.log({ response })
      const { status, data } = response.data;
  
      if (status === 'user_verified') {
        setIsPolling(false);  // Stop polling
        console.log('User profile data:', data);
        const { name: { first, last }, onlineIdentities: { email }, phoneNumbers } = data
        setName((first + " " + last)?.trim() || name)
        setTcName((first + " " + last)?.trim())
        setPhone(phoneNumbers.length ? String(phoneNumbers[0]).slice(-10) : phone)
        setTcPhone(phoneNumbers.length ? String(phoneNumbers[0]).slice(-10) : '')
        setTcEmail(email)
  
      } else if (status === 'user_rejected') {
        setIsPolling(false);  // Stop polling
        console.log('User rejected the flow.');
      } else if (status === 'flow_invoked') {
        setPollingCount(prev => prev + 1);  // Continue polling
      } else {
        setIsPolling(false);
        console.log('something went wrong with status 200');
      }
    } catch (error) {
      setPollingError('Error fetching Truecaller status');
      console.error('Polling error:', error);
      setIsPolling(false);  // Stop polling on error
    }
  
    // Stop polling after max attempts
    if (pollingCount >= maxPollingAttempts) {
      setIsPolling(false);
      console.log('Max polling attempts reached');
    }
  };

    return (
        <div className='input_custom_wrapper' style={{ marginBottom: "20px", width: "300px", gap: "10px" , display:"flex", justifyContent: "space-between", alignItems:"center", flexWrap: "wrap"}}>
            {newContent ? <h2>Book you home visit today!</h2> : null}
            <TextField
                variant="outlined"
                fullWidth
                placeholder={"Ex: 9876543210"}
                value={phone}
                onChange={handleChange}
                onClick={() => handleInputClick()}
                error={!!phoneError}
                helperText={phoneError}
                // onClick={() => handleInputClick()}
                InputProps={{
                    sx: {
                        backgroundColor: '#f7f7f7',
                        borderRadius: '10px',
                        width: "auto",
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: phoneError ? 'red' : 'transparent',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: phoneError ? 'red' : 'transparent',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: phoneError ? 'red' : 'transparent',
                        },
                        '& .MuiInputBase-input': {
                            color: '#424242',
                            fontSize: '16px',
                        },
                    },
                }}
            />
            {/* {error && (
        <Typography
          variant="body2"
          sx={{ color: 'red', marginTop: '5px', marginLeft: '10px' }}
        >
          {error}
        </Typography>
      )} */}
            <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{
                        height: "46px",
                        width: "auto",
                        fontWeight: "600",
                        padding: "12px 16px 12px 16px",
                        gap: "8px",
                        borderRadius: "10px",
                        fontSize: "14px",
                        background: (phoneError || !phone) ? "#dcdcdc" : "#FB9F40"
                    }}
                    disabled={(phoneError || !phone)}
                    onClick={(e) => handlePayment(e)}
                >
                    {newContent ? "Continue" : !newCityPage ? "Confirm Booking" : "Proceed to Booking"}
                </Button>
            <div style={{ textAlign: "center" }}>
            </div>
            <PaymentStatusPopup open={openPopup} onClose={() => setOpenPopup(false)} orderId={currentOrderId} paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus} />

        </div >
    );
};

export default InputWithLabel;
