import React from "react";
import '../Feedback/Css/style.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import patientPic from './Images/patientPic.png';

const testimonials = [
  {
    id: 1,
    name: 'Sindhu Pillai',
    title: 'IT Professional',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `Kyno health sent Dr. Prem Kalagi to access my Granny's health condition to a remote place near Bangalore airport at 22:30hrs. He was a great doctor and a even better Human. I'm amazed by their service and would request them to continue the great work. For people who cannot even get up to go see the doctor, they are saviours. Kudos to you all!`,
    image: patientPic, // Replace with your image path
    city: 'Delhi'
  },
  {
    id: 2,
    name: 'Makrand Shreelochan',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `Really impressive specially about the services they provide is really amazing. 24/7 services available. Highly skilled and experienced Doctors team they have the staff is so supportive. Overall it's a great experience with Kyno Health.`,
    image: patientPic, // Replace with your image path
    city: 'Delhii'
  },
  {
    id: 3,
    name: 'Rajesh',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `Very nice initiative for elderly people and those who are not able to move to hospital.I have used the service and Fully satisfied as they provide all emergency medication at doorstep.`,
    image: patientPic, // Replace with your image path
    city: 'Noida'
  },
  {
    id: 4,
    name: 'Nitu Kiran',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `The doctor reached within 60 minutes. Awesome support and moreover all the blood tests were done at home. Kyno health was a great saviour.`,
    image: patientPic, // Replace with your image path
    city: 'Bangalore'
  },
  {
    id: 5,
    name: 'Venugopal Ayilliath',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `This is an excellent service, particularly for elderly people like us. Our experience with the doctor who visited us at home was highly satisfying. Kyno Health’s responder lady and the overall system appeared very professional, fast and impressive. Will certainly utilise their service when support is desired at home, particularly for non-specialist consultation. Many thanks.`,
    image: patientPic, // Replace with your image path
    city: 'Bangalore'
  },
  {
    id: 6,
    name: 'Sowmya H',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `There was an emergency at home and it was difficult to take my 85 yr old mother to the Hospital. Called Kyno and Manish arranged Dr within 45 mins as promised.. I recommend kyno for those who have elderly parents at home and need Dr help at the earliest...Thanks Manish and Dr Prem.`,
    image: patientPic, // Replace with your image path
    city: 'Bangalore'
  },
  {
    id: 7,
    name: 'Soundarya Nachappa',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `Kyno helped us during emergency. The doctor came on time and took good care of the patient. After consultation was also very good and on time. Happy with overall experience. Thank you`,
    image: patientPic, // Replace with your image path
    city: 'Bangalore'
  },
  {
    id: 8,
    name: 'Shahnaz Naseem',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `Very efficient service rendered by Kyno. My aged father was down with Fever and cold and was not in a position to travel to the hospital.I contacted Kyno and within 30 minutes they sent Dr.N.Shalini who visited my house and checked him thoroughly and started treatment.Highly appreciate her approach, patience and treatment.My Dad is now fit and fine and I am more than happy that the treatment could be done at the comfort of my house .`,
    image: patientPic, // Replace with your image path
    city: 'Bangalore'
  },
  {
    id: 6,
    name: 'Yuvika Mishra',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `Such a really good doctor I am very impressed with her very friendly very motivated and affordable thanks kyno health for your best service at home ❤️❤️❤️❤️`,
    image: patientPic, // Replace with your image path
    city: 'Bangalore'
  },
  {
    id: 9,
    name: 'Sumit Basu',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `very prompt and effective service. i have used their service twice and the doctors were very kind and capable. thank you.`,
    image: patientPic, // Replace with your image path
    city: 'Bangalore'
  },
  {
    id: 10,
    name: 'Ajay Thakur',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `Excellent service by Kyno and Doctor who visited to my place is highly knowledgeable and explained everything perfectly , Highly recommended.`,
    image: patientPic, // Replace with your image path
    city: 'Bangalore'
  },
  {
    id: 11,
    name: 'KADAMBARI SRIKANTHAN',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `This is a new organ[ization] at Bangalore. Came to know of it thru Google search. The doctor came for a home visit promptly and prescribed medicines. Her visit was very helpful`,
    image: patientPic, // Replace with your image path
    city: 'Bangalore'
  },
  {
    id: 12,
    name: 'Kyno Patient',
    title: 'Teacher',
    tags: ['Diabetes Patient', 'Chronic Disease Management'],
    feedback: `We had a wonderful experience as doctor's visit at home was a big relief for our ageing great grand mother-in-law. One must try.`,
    image: patientPic, // Replace with your image path
    city: 'Bangalore'
  },
  // Add more testimonial objects as needed
];

let getInitials = function (name) {
  const words = name.split(" ");
  if (words.length == 1) {
    return words[0].slice(0, 2).toUpperCase()
  }
  let initials = "";
  let count = 0;
  words.forEach(word => {
    if (count >= 2) {
      return;
    }
    initials += word.charAt(0).toUpperCase();
    count++;
  });
  return initials;
}

const INFINITE = 9999999999999;

function Feedback({slidesPerViewMobile = 2, slidesPerViewDesktop = 3, autoscroll=true}) {
  return (
    <section className="patient-carousel">
      <h2 className="carousel-heading">Google Rating</h2>
      <div className="carsouel_inner">
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={30}
        // slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{
          delay: autoscroll ? 4000 : INFINITE, // 3-second delay between slides
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        breakpoints={{
          768: { slidesPerView: slidesPerViewMobile || 2 },
          1024: { slidesPerView: slidesPerViewDesktop || 3 },
        }}
        className=""
        key={Math.random(0, 99999)}
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.id}>
            <div className="carsouel_item">
              <div className="review_card treatmentPageReviewCard">
                <div className="reviewcard_header">
                  <div className="reviewcard_image">
                    <span>{getInitials(testimonial.name)}</span>
                  </div>
                  <div className="reviewcard_detail">
                    <p className="reviewcard_name">{testimonial.name}</p>
                    <div className="reviewcard_star_thumb">
                      <div className="reviewcard_star_ratings" title="5 Stars">
                        <svg viewBox="0 0 51 48" className="widget-svg"
                          style={{ width: "11px", height: "11px", transition: "transform 0.2s ease-in-out 0s" }}>
                          <path className="star" d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
                            style={{ fill: "rgb(255, 204, 0)", transition: "fill 0.2s ease-in-out 0s" }}></path>
                        </svg>
                        <svg viewBox="0 0 51 48" className="widget-svg"
                          style={{ width: "11px", height: "11px", transition: "transform 0.2s ease-in-out 0s" }}>
                          <path className="star" d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
                            style={{ fill: "rgb(255, 204, 0)", transition: "fill 0.2s ease-in-out 0s" }}></path>
                        </svg>
                        <svg viewBox="0 0 51 48" className="widget-svg"
                          style={{ width: "11px", height: "11px", transition: "transform 0.2s ease-in-out 0s" }}>
                          <path className="star" d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
                            style={{ fill: "rgb(255, 204, 0)", transition: "fill 0.2s ease-in-out 0s" }}></path>
                        </svg>
                        <svg viewBox="0 0 51 48" className="widget-svg"
                          style={{ width: "11px", height: "11px", transition: "transform 0.2s ease-in-out 0s" }}>
                          <path className="star" d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
                            style={{ fill: "rgb(255, 204, 0)", transition: "fill 0.2s ease-in-out 0s" }}></path>
                        </svg>
                        <span className="rating_count review_card_font"> 5/5 </span>
                      </div>
                      <div className="reviewcard_record">
                        <p className="reviewcard_recommended">
                          <img width='10px' height='10px' src="https://img.pristyncare.com/static_pages%2FcompanyClinic%2FIcon%20open-thumb-up.svg" alt="thumbs up" />
                          <span className="" style={{ marginLeft: "10px"}}>Recommends</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reviewcard_content">
                  <p className="reviewcard_desc"> {testimonial.feedback}</p>
                </div>
                <div className="reviewcard_bottom">
                  <div className="reviewcard_city">City : <span>{testimonial.city}</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      </div>
    </section>
  );
}

export default Feedback;