import React from "react";
import "./Css/style.css";
import doctorPatient1 from "./Images/docPatient1.png"; // Replace with the actual image path
import doctorPatient2 from "./Images/docPatient2.png";
import doctorPatient3 from "./Images/docPatient3.png";

const HealthcareHighlights = () => {
  return (
    <div className="why-kyno-section">

    <div className="kyno-content">
      <h2>Why Kyno exists</h2>
      <p>
        India ranks among <strong>Top 3</strong> in the Medical tourism destinations in Asia, but citizens struggle to find accessible healthcare. The healthcare sector is poised to grow into a <strong>$610 billion industry by 2026,</strong> but the innovation in primary curative care remains insufficient.
      </p>
      {/* <p>
      India ranks among <strong>Top 3</strong> in the Medical tourism
      destinations in Asia, but citizens struggle to find accessible
      healthcare.
    </p>
    <p>
      The healthcare sector is poised to grow into a{" "}
      <strong>$610 billion industry by 2026</strong>, but the innovation in
      primary curative care remains insufficient.
    </p> */}
      <p className="kyno-highlight">
        <strong>At Kyno, we are here to bridge this gap.</strong>
      </p>
      </div>
      <div className="highlights-container">
        {/* First Card */}
        <div className="highlight-card">
          <img
            src={doctorPatient1}
            alt="Doctor with patient"
            className="highlight-image"
          />
          <div className="highlight-text">
            <h3>Healthcare when it matters</h3>
            <p>
              India has medical talent, but care is uneven. Kyno delivers
              patient-first care with an array of services and redefines
              convenience.
            </p>
          </div>
        </div>

        {/* Second Card */}
        <div className="highlight-card">
          <img
            src={doctorPatient2}
            alt="Doctor assisting a patient"
            className="highlight-image"
          />
          <div className="highlight-text">
            <h3>Bridging gaps, building trust</h3>
            <p>
              The healthcare sector is growing, but primary care innovation
              remains limited. Kyno bridges the gap with reliable, accessible and
              uncompromised healthcare.
            </p>
          </div>
        </div>

        {/* Third Card */}
        <div className="highlight-card">
          <img
            src={doctorPatient3}
            alt="Doctor assisting a patient"
            className="highlight-image"
          />
          <div className="highlight-text">
            <h3>Bringing precision and care</h3>
            <p>
              We’re creating an accurate, accessible healthcare model that combines technology, compassion, and innovation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthcareHighlights;
