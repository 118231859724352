import React, { useState } from 'react';
import '../Booking/Css/style.css';
import close from './Images/Close.png';
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

const Booking = () => {

  const navigate = useNavigate(); 

  const goToBookingSuccessfull = () => {
    navigate("/booking-successfull"); 
  };

  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAppointment, setIsAppointment] = useState(false);
  const [cityName, setCityName] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  // Sample data for days, hours, minutes, and AM/PM
  const days = ["Today", "Tomorrow", "23 Nov", "24 Nov"];
  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 60 }, (_, i) => (i < 10 ? `0${i}` : i + 1));
  const amPm = ["AM", "PM"];
  // Toggle modal visibility

  // Validate phone number format
  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Indian phone number validation
    return phoneRegex.test(phone);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(phoneNumber)) {
      setErrorMessage('Invalid phone number format');
    } else {
      setErrorMessage('');
      setIsSubmit(true)
      // alert(`Form submitted:\nName: ${fullName}\nPhone: ${phoneNumber}`);
      // Handle form submission logic here (e.g., API call)
    }
  };

  const toggleSheet = () => {
    setIsSubmit(!isSubmit);
  };

  const showAppointment = () => {
    setIsAppointment(true);
  }

  const handleCityChange = (e) => {
    setCityName(e.target.value);
  };

  const handleBooking =(e)=>{

  }

  return (
    <div className="form-container">
      {!isAppointment && (
        <div >
          <form className="visit-form" onSubmit={handleSubmit}>
            <h2 className='booking_title'>Book your home visit today</h2>

            {/* Full Name Input */}
            <div className="form-group">
              <label htmlFor="fullName">Full Name</label>
              <input
                type="text"
                id="fullName"
                placeholder="enter name ..."
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </div>

            {/* Phone Number Input */}
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone number</label>
              <div className="phone-input-container">
                <span className="country-code">+91</span>
                <span>|</span>
                <input
                  type="tel"
                  className="phone-input"
                  id="phoneNumber"
                  placeholder="enter 10 digit number ..."
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>

            {/* Submit Button */}
            <button type="submit" className="submit-btn">
              Continue
            </button>
          </form>

          {isSubmit ?
            <div className={`bottom-sheet ${isSubmit ? 'open' : ''}`}>
              <div className="sheet-content">
                <div className="sheet-header">
                  <span className="sheet_title">Hi, {fullName}</span>
                  <button className="close-btn" onClick={toggleSheet}>
                    <img src={close} alt="" />
                  </button>
                </div>
                <div className='content_section'>
                  <div>

                  </div>
                  <p className="sheet_description">
                    To get started, please verify mobile number
                  </p>
                  <div className="number_btn">
                    <button className='verify_number' onClick={showAppointment}>Use {phoneNumber}</button>
                  </div>
                  <div>
                    <p>By continuing</p>
                  </div>
                </div>

              </div>
            </div> : ''}
        </div>
      )}

      {isAppointment && (
        <div className="appointment-container">
          <h2 className='appointment_title'>Appointment details</h2>
          <div className="appointment-details">
            <label>City</label>
            <select className='field-control' onClick={handleCityChange}>
              <option>New Delhi</option>
              <option>Mumbai</option>
              <option>Bangalore</option>
            </select>

            <label>Select time</label>
            <div className="time-options">
              <button className={`btn ${isModalOpen ? 'disable' : 'enable'}`} onClick={() => setModalOpen(false)}>Within 60 mins</button>
              <button className={`btn ${isModalOpen ? 'enable' : 'disable'}`} onClick={() => setModalOpen(true)}>
                Schedule later
              </button>
            </div>

            <div className='bottom_section'>

              <button className="book-now" onClick={goToBookingSuccessfull}>Book now</button>
              <p className='visit_fee'>Pay ₹99 now, rest at the visit</p>
            </div>

          </div>

          {/* Bottom Sheet Modal */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setModalOpen(false)}
            className="bottom-sheet2"
            overlayClassName="overlay"
          >
            <div className="modal-header">
              <h3>Select time</h3>
              <button className="close-btn" onClick={() => setModalOpen(false)}>
                &times;
              </button>
            </div>
            <div className="scroll-container">
              <div className="scroll-column">
                {days.map((day, index) => (
                  <div key={index} className="scroll-item">
                    {day}
                  </div>
                ))}
              </div>
              <div className="scroll-column">
                {hours.map((hour, index) => (
                  <div key={index} className="scroll-item">
                    {hour}
                  </div>
                ))}
              </div>
              <div className="scroll-column">
                {minutes.map((minute, index) => (
                  <div key={index} className="scroll-item">
                    {minute}
                  </div>
                ))}
              </div>
              <div className="scroll-column">
                {amPm.map((item, index) => (
                  <div key={index} className="scroll-item">
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <button className="confirm-btn" onClick={() => setModalOpen(false)}>Confirm</button>
          </Modal>

        </div>
      )}

    </div>
  );
};
export default Booking;
