import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';
import InputWithLabel from '../../homePage/inputtypepay';

function NewHeroContent({ isBookSlot, customCallus, city }) {
  return (

    <>
      <section className="baner-region new-content">

        <div className="container">
          <div className="row">
            <div className="col-md-5 col-xs-12">
              <div className='left-side ml-70'>
                <h3>Experienced doctor at home</h3>
                <h1>in 60 MINUTES</h1>
                <div className={"featuresContainer"}>
                  <ul className={"featureList"}>
                  <li>Best GPs In Town</li>
                    <li>Book Instantly</li>
                    <li>Available 24*7</li>
                    <li>Best prices</li>
                  </ul>
                </div>
                <InputWithLabel newContent={true} city={city} />

              </div>
            </div>


            <div className="col-md-7 col-xs-12">
              <div className='right-side'>

                ''
                {/* <img src={bnrImg} alt='bnr-img' className='bnrimg img-fluid'/> */}

              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default NewHeroContent
